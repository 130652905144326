<template>
  <v-dialog persistent width="700px" v-model="dialog">
    <v-card class="px-2 py-2">
      <v-btn icon ripple @click="close" style="float: right;">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <iframe
        v-if="dialog"
        :src="`https://player.vimeo.com/video/${videoID}`"
        width="675"
        height="350"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    videoID: {
      type: String,
    },
    dialog: {
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit('update:dialog', false);
    },
  },
};
</script>

<style></style>
