<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs9>
        <view-title
          :address="address"
          :timezone="timezone"
          :dropDownItems="availableSites"
          label="Search Sites"
          :search="true"
        />
      </v-flex>
      <v-flex xs3 style="position: relative;">
        <br />
        <div class="FAB-Extended-PrimaryColor-Enabled" v-if="canEditSite">
          <v-btn type="submit" flat @click="goToLocationSettings">
            <v-icon left dark>mdi-settings</v-icon>Configuration
          </v-btn>
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn outline class="svg-videocam" v-on="on" @click="toggleDialog">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Training Video</span>
        </v-tooltip>
        <video-player :dialog.sync="videoDialog" :videoID="videoID" />
      </v-flex>
    </v-layout>
    <v-spacer></v-spacer>
    <loading-wrapper :loading="loading" mt="20vh" :size="100">
      <v-layout class="mt-1 ml-1 ma-0 H6-Selected-On-Surface-High-Emphasis-Left">
        <span style="font-size: 32px; font-weight: 500;">Station List</span>
        <v-layout row wrap justify-end>
          <v-flex shrink v-if="!isDH && isAdmin">
            <v-btn outline color="primary" @click="goToBusinessUnitEditor({})"
              >Add Business Unit</v-btn
            >
          </v-flex>
          <v-flex shrink v-if="locations.length > 0">
            <div v-if="canAddBrand">
              <v-btn type="submit" color="primary" :disabled="isDH" @click="addNewBrand">
                <v-icon left dark>mdi-plus</v-icon>ADD STATION
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row wrap class="list-container">
        <v-flex xs12>
          <template v-for="location of locations">
            <v-card :key="location.id + 'h3'" class="v-card-custom">
              <v-layout class="locationPanel">
                <v-flex shrink>
                  <h3 class="brand-name">
                    {{ location.name }}
                    <v-chip
                      color="primary"
                      text-color="white"
                      style="border-radius:6px;"
                      label
                      v-if="location && location.market_place"
                    >
                      Marketplace
                    </v-chip>
                  </h3>
                  <h4 class="Brand-Unit">
                    Business Unit Number
                    {{ (location.meta && location.meta.unit) || 'N/A' }}
                  </h4>
                </v-flex>
                <v-flex class="pencil">
                  <v-btn
                    icon
                    @click="goToBusinessUnitEditor(location)"
                    v-if="(isAdmin || isOperator) && !isDH"
                  >
                    <v-icon>mdi-settings</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-data-table
                :headers="headers"
                :items="location.brands"
                hide-actions
                :search="filter"
                :key="location.id"
                :loading="loading"
                class="data-table"
              >
                <template v-slot:items="props">
                  <tr>
                    <td
                      style="max-width: 100px !important; overflow-wrap: break-word; width: 20%;"
                      @click="goToBrand({ id: props.item.id, name: props.item.name })"
                      class="clickable"
                    >
                      {{ props.item.name }}
                    </td>
                    <td>
                      <loading-wrapper :loading="!props.item" justify="justify-left" :size="20">
                        <span
                          v-if="props.item.brand_location_description"
                          @click="goToBrand({ id: props.item.id, name: props.item.name })"
                          class="clickable"
                          style="width: 30%;"
                          >{{ props.item.brand_location_description }}</span
                        >
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on }">
                            <a
                              v-on="!canEditSite && on"
                              v-bind:class="{ clickable: true, disabled: !canEditSite }"
                              @click="
                                canEditSite && editBrandDescription(props.item.id, props.item.name)
                              "
                              >Add Description</a
                            >
                          </template>
                          <span>Contact Implementation Team</span>
                        </v-tooltip>
                      </loading-wrapper>
                    </td>
                    <td style="width: 25%;">
                      <loading-wrapper
                        :loading="!props.item.hours"
                        justify="justify-left"
                        :size="20"
                      >
                        <div>
                          Pickup:
                          <span
                            class="Body-1-Black-High-Emphasis-Left"
                            :style="
                              showBoldedHours(props.item.pickup_times) ? 'font-weight: bold' : ''
                            "
                          >
                            <a
                              @click="
                                goToBrand({
                                  id: props.item.id,
                                  name: props.item.name,
                                  tab: 'pickup',
                                })
                              "
                              >{{ props.item.pickup_times }}</a
                            >
                          </span>
                        </div>
                        <div v-if="props.item.delivery_times">
                          Delivery:
                          <span
                            class="Body-1-Black-High-Emphasis-Left"
                            :style="
                              showBoldedHours(props.item.delivery_times) ? 'font-weight: bold' : ''
                            "
                          >
                            <a
                              @click="
                                goToBrand({
                                  id: props.item.id,
                                  name: props.item.name,
                                  tab: 'delivery',
                                })
                              "
                              >{{ props.item.delivery_times }}</a
                            >
                          </span>
                        </div>
                        <v-layout row>
                          <v-flex xs12>
                            <div
                              v-if="props.item.brand_status && props.item.brand_status.is_closed"
                              class="Temporarily-closed"
                            >
                              <v-img
                                class="svg-logo"
                                :src="require('@/assets/store-front.svg')"
                              ></v-img>
                              {{ props.item.reopen_message }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </loading-wrapper>
                    </td>
                    <td
                      @click="goToBrand({ id: props.item.id, name: props.item.name })"
                      class="clickable"
                      style="width: 25%;"
                    >
                      <loading-wrapper
                        :loading="!props.item.type_of_kds"
                        justify="justify-left"
                        :size="20"
                      >
                        <v-chip
                          disabled
                          label
                          draggable="false"
                          class="kds"
                          v-for="(kds, k) in props.item.type_of_kds"
                          v-bind:key="k"
                          >{{ kds }}</v-chip
                        >
                      </loading-wrapper>
                    </td>
                    <td style="padding: 0 !important;" v-if="shouldShowMenuColumn">
                      <v-btn
                        class="ma-0"
                        :disabled="!brandMenuLink(props.item)"
                        outline
                        :href="`${brandMenuLink(props.item)}`"
                        :target="isCentricOSIntegrated(props.item) ? '_blank' : '_self'"
                        style="text-decoration:none"
                        color="primary"
                      >
                        {{ getMenuButtonText(props.item) }}
                      </v-btn>
                    </td>
                    <td v-if="!isDH">
                      <loading-wrapper
                        :loading="brandsUpdating[props.item.id]"
                        justify="justify-left"
                        :size="20"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-checkbox
                                v-on:change="toggleLocationBrandVisibility(props.item, $event)"
                                :input-value="props.item.is && props.item.is.hidden"
                                hide-details
                                on-icon="mdi-eye-off"
                                off-icon="mdi-eye"
                                color="none"
                                @click.native.stop
                                :false-value="false"
                                :true-value="true"
                                :disabled="!isToggleVisibilityEnabled"
                              />
                            </div>
                          </template>
                          <span v-if="props.item.is && props.item.is.hidden">
                            Hidden in App
                          </span>
                          <span v-else>
                            Display in App
                          </span>
                        </v-tooltip>
                      </loading-wrapper>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-flex>
      </v-layout>
    </loading-wrapper>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import { CalendarTypes, TypeOfKds, VimeoVideoID, UserRoles } from '@/constants';
import * as BrandHoursHelpers from '@/views/sites/helpers';
import videoPlayer from '@/components/videoPlayerModal';
import { centricOSURL } from '@/helpers';

export default {
  components: {
    videoPlayer,
  },
  data: () => ({
    calendars: {},
    brandsUpdating: {},
    videoDialog: false,
    filter: '',
    centricOS: {
      brandMap: {},
    },
  }),
  watch: {
    currentSite: {
      handler(newValue, oldValue) {
        if (!newValue || (newValue && oldValue && newValue.id === oldValue.id)) return;
        this.getSiteConfigsAndCalendars();
      },
      immediate: true,
    },
    centricOSBrandsList: {
      handler(newValue, oldValue) {
        if (!newValue?.length || newValue?.length === oldValue?.length) return;
        this.getStationCentricOSInfoForSite(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('calendar', ['getCalendar']),
    ...mapActions('sites', ['getConfigPublic', 'patchLocationBrand', 'fetchSite', 'getLocation']),
    ...mapActions('centricOSMenu', ['fetchLocalBrand']),
    ...BrandHoursHelpers,
    showBoldedHours(hours) {
      return hours === 'Closed' || hours === 'N/A';
    },
    async getSiteConfigsAndCalendars() {
      if (!this.currentSite || !this.currentSite.locations) {
        return;
      }
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: this.currentSite.name,
        to: { name: 'site-info', params: { site_id: this.currentSite.id } },
      });
      const calendars = {};
      await Promise.all(
        this.currentSite.locations.map((location) => {
          if (!location.brands) return false;
          return Promise.all(
            location.brands.map(async (brand) => {
              calendars[brand.id] = await this.getCalendar({ id: brand.id });
            }),
          );
        }),
      );
      this.calendars = calendars;
    },
    async addNewBrand() {
      this.$router.push({
        name: 'brand-new',
      });
    },
    async goToLocationSettings() {
      this.$router.push({
        name: 'site-settings',
        params: {
          site_id: this.currentSite.id,
        },
      });
    },
    async goToBusinessUnitEditor(location) {
      const isNewLocation = !location?.id;
      if (isNewLocation) {
        this.$router.push({
          name: 'location-settings',
          params: {
            site_id: this.currentSite.id,
            site: this.currentSite,
          },
        });
      } else {
        const loc = await this.getLocation({
          id: location.id,
          nocache: true,
        });
        if (loc && loc.market_place) {
          localStorage.location = JSON.stringify(loc);
          await this.goToMarketplaceSettings();
        } else {
          this.$router.push({
            name: 'location-settings',
            params: {
              site_id: this.currentSite.id,
              location_id: location.id,
              site: this.currentSite,
            },
          });
        }
      }
    },
    async goToMarketplaceSettings() {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: 'Configure Marketplace',
        last: true,
        to: {
          name: 'marketplace-settings',
          params: {
            site_id: this.$route.params.site_id,
          },
        },
      });
      this.$router.push({
        name: 'marketplace-settings',
        params: {
          site_id: this.$route.params.site_id,
        },
      });
    },
    async editBrandDescription(id, name) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 3,
        title: name,
        to: {
          name: 'brand-settings',
          params: { brand_id: id, site_id: this.currentSite.id },
        },
      });
      this.$router.push({
        name: 'brand-settings',
        params: {
          brand_id: id,
        },
        query: {
          focus: 'location_description',
        },
      });
    },
    areThereLinkedMenus(brand) {
      if (!brand.company || this.companyMenus.length === 0) return false;
      const stationCompanyMenu = this.companyMenus.find(
        (companyMenu) => companyMenu.companyId === brand.company,
      );
      return (
        brand.menus &&
        brand.menus.length > 0 &&
        stationCompanyMenu.menus.some((menu) => menu.is && menu.is.linked)
      );
    },
    brandMenuLink(station) {
      if (!station) return '';
      if (this.isCentricOSIntegrated(station)) {
        const centricOSBrand = this.centricOS.brandMap[station.meta.menu.brand];
        if (!centricOSBrand) return '';
        return `${centricOSURL()}/local-menus/${centricOSBrand.local_menu_group_id}/brand/${
          centricOSBrand.id
        }/menus`;
      }
      if (!this.areThereLinkedMenus(station)) return '';
      return `#/menu/sector/${station.sector}/brand/company/${station.company}/brands/${station.id}/`;
    },
    getMenuButtonText(brand) {
      if (
        this.centricOS.brandMap?.[brand?.meta?.menu?.brand] &&
        this.isCentricOSIntegrated(brand)
      ) {
        return 'AVAILABLE ON CENTRIC OS';
      }
      if (this.areThereLinkedMenus(brand)) {
        return 'VIEW MENU';
      }
      return 'MENU UNAVAILABLE';
    },
    goToBrand({ id, name, tab }) {
      if (
        !this.userHasAnyRole([
          UserRoles.admin,
          UserRoles.sys_admin,
          UserRoles.im_user,
          UserRoles.site_operator,
        ])
      ) {
        return;
      }
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: name,
        to: {
          name: `brand-overview-${tab || 'menus'}`,
          params: { brand_id: id, site_id: this.currentSite.id },
        },
      });
      this.$router.push({
        name: `brand-overview-${tab || 'menus'}`,
        params: { site_id: this.currentSite.id, brand_id: id },
      });
    },
    async toggleLocationBrandVisibility(brand, isHidden) {
      try {
        brand.is = { ...brand.is, hidden: isHidden };
        this.$set(this.brandsUpdating, brand.id, true);
        await this.patchLocationBrand({ id: brand.id, is: { hidden: isHidden } });
        this.fetchSite({
          // no await since we just want to clear Back End Cache
          id: this.currentSite.id,
          nocache: true,
        });
        this.$set(this.brandsUpdating, brand.id, false);
        this.$toast.success(`Successfully updated ${brand.name} station status`);
      } catch (err) {
        console.error(`Could not update station ${brand.id}`, err);
        this.$toast.error(`Could not update ${brand.name} station status`);
      }
    },
    toggleDialog() {
      this.videoDialog = !this.videoDialog;
    },
    async getStationCentricOSInfoForSite(centricOSBrandList = []) {
      if (!centricOSBrandList.length) return;
      const localBrands = await Promise.all(
        centricOSBrandList.map((brandId) => this.fetchLocalBrand({ localBrandId: brandId })),
      );
      localBrands.forEach((b) => {
        if (b?.id) {
          this.$set(this.centricOS.brandMap, b?.id, b);
        }
      });
    },
  },
  computed: {
    ...mapState('adminPanel', ['user']),
    ...mapState('users', ['customPermissions']),
    ...mapState('sites', ['active_site', 'multigroups', 'multigroupMap', 'siteMap']),
    ...mapGetters('adminPanel', ['isAdmin', 'hasRole', 'userHasAnyRole']),
    ...mapGetters('sites', {
      isDHSite: 'isDHSite',
    }),
    ...mapGetters('adminPanel', ['userHasAnyRole', 'hasSpecificPermissions']),
    ...mapGetters('menus', ['getCompanyMenus']),
    ...mapGetters('centricOSMenu', ['isCentricOSIntegrated']),
    canEditSite() {
      // add more permissions here if necessary
      return ['admin'].some((r) => this.hasRole(r));
    },
    canAddBrand() {
      // add more permissions here if necessary
      if (this.isDHSite(this.currentSite)) {
        return false;
      }
      return ['admin'].some((r) => this.hasRole(r));
    },
    isDH() {
      return this.isDHSite(this.currentSite);
    },
    isOperator() {
      return this.hasRole('site_operator');
    },
    timezone() {
      return (
        this.locations &&
        this.locations[0] &&
        this.locations[0].brands &&
        this.locations[0].brands[0] &&
        this.locations[0].brands[0].timezone
      );
    },
    address() {
      return this.currentSite && this.currentSite.address && this.currentSite.address.address;
    },
    shouldShowMenuColumn() {
      if (this.isDHSite(this.currentSite)) {
        return false;
      }
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      const user_permissions =
        (this.user && this.user.permissions && this.user.permissions.scopes) || [];
      return hasHighPermissionRole || user_permissions.includes(this.customPermissions.menuAccess);
    },
    currentSite() {
      return this.siteMap[this.$route.params.site_id] || {};
    },
    availableSites() {
      let sites = [];
      if (localStorage.siteDefaultAppID === 'all') {
        sites = Object.values(this.siteMap);
      } else {
        sites = this.multigroupMap[localStorage.siteDefaultAppID]
          ? this.multigroupMap[localStorage.siteDefaultAppID].groups
          : [];
      }
      return sites;
    },
    locations() {
      if (!this.currentSite.locations) return [];
      return cloneDeep(this.currentSite.locations).map((location) => {
        if (!location.brands) {
          location.brands = [];
        }
        location.brands = location.brands.map((brand) => {
          const calendar = this.calendars[brand.id] || {};
          if (!brand.brand_status) brand.brand_status = {};
          const typeOfKds = [];
          const brand_location_description = brand.location_description || '';
          brand.location_description = brand_location_description;
          const brandReopenTime = this.getReopenTime(brand, calendar.events);
          const pickupTimes = this.getOpenHoursForTheDay(
            brandReopenTime,
            brand,
            calendar,
            CalendarTypes.business,
          );
          const deliveryTimes = this.getOpenHoursForTheDay(
            brandReopenTime,
            brand,
            calendar,
            CalendarTypes.delivery,
          );
          if (brand.meta) {
            if (brand.meta.type_of_kds) {
              if (brand.meta.type_of_kds.nextep) {
                typeOfKds.push(TypeOfKds.nextep.name);
              }
              if (brand.meta.type_of_kds.agilysys) {
                typeOfKds.push(TypeOfKds.agilysys.name);
              }
              if (brand.meta.type_of_kds.volante) {
                typeOfKds.push(TypeOfKds.volante.name);
              }
              if (brand.meta.type_of_kds.cdl) {
                typeOfKds.push(TypeOfKds.cdl.name);
              }
            }
            // Default
            if (brand.meta.has_kds && typeOfKds.length === 0) {
              typeOfKds.push(TypeOfKds.cdl.name);
            }
          }
          if (typeOfKds.length === 0) {
            typeOfKds.push('N/A');
          }
          const brandWithAdditionalData = {
            ...brand,
            brand_location_description,
            pickup_times: pickupTimes,
            delivery_times: deliveryTimes,
            type_of_kds: typeOfKds,
          };
          if (brandReopenTime) {
            const todaysDate = DateTime.local();
            const isClosed = brandReopenTime >= todaysDate;
            const brandStatus = {
              is_closed: isClosed,
            };
            const reopenMessage = isClosed
              ? this.getReopenMessage(brandReopenTime, brand.timezone)
              : '';
            brandWithAdditionalData.reopen_message = reopenMessage;
            brandWithAdditionalData.brand_status = brandStatus;
          }
          return brandWithAdditionalData;
        });
        return location;
      });
    },
    loading() {
      return !this.currentSite || !this.currentSite.id;
    },
    headers() {
      const headers = [
        {
          text: 'Station Name',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Location',
          align: 'left',
          value: 'publicConfig.brand_location_description',
        },
        {
          text: "Today's Hours",
          align: 'left',
          value: 'hours',
        },
        {
          text: 'Type of KDS',
          align: 'left',
          value: 'type_of_kds',
        },
      ];
      if (this.shouldShowMenuColumn) {
        headers.push({
          text: 'Menu',
          align: 'left',
          value: 'brandMenusLink',
        });
      }
      if (!this.isDH) {
        headers.push({
          text: 'Status',
          align: 'left',
          value: 'is.hidden',
          width: 20,
        });
      }
      return headers;
    },
    isToggleVisibilityEnabled() {
      const hasHighPermissionRole = this.userHasAnyRole([UserRoles.admin, UserRoles.sys_admin]);
      const hasSpecificPermission = this.hasSpecificPermissions([
        this.customPermissions.editBrandVisibility,
      ]);
      return hasHighPermissionRole || hasSpecificPermission;
    },
    videoID() {
      return VimeoVideoID.stationList;
    },
    companyMenus() {
      const siteCompanyIds = new Set();
      const companyMenus = [];

      this.currentSite.locations.forEach((location) => {
        if (!location.brands) return;
        const locationBrands = cloneDeep(location.brands);
        locationBrands.forEach((brand) => {
          if (!brand.company) return;
          siteCompanyIds.add(brand.company);
        });
      });

      siteCompanyIds.forEach((siteCompanyID) => {
        if (!siteCompanyID) return;
        companyMenus.push({ companyId: siteCompanyID, menus: this.getCompanyMenus(siteCompanyID) });
      });
      return companyMenus;
    },
    centricOSBrandsList() {
      if (!this.active_site?.locations) return [];
      const centricOSBrands = new Set();
      this.active_site.locations.forEach((location) =>
        location.brands?.forEach((station) => {
          if (!this.isCentricOSIntegrated(station)) return;
          centricOSBrands.add(station.meta.menu.brand);
        }),
      );
      return [...centricOSBrands];
    },
  },
};
</script>

<style scoped>
.locationPanel {
  border-radius: 5px;
  padding: 24px 0 0 40px;
}
.locationPanel .pencil {
  display: flex;
  justify-content: flex-end;
}
.pencil button {
  margin-right: 40px;
}
.disabled {
  color: grey;
  pointer-events: none;
}
.v-card-min {
  min-width: 100px;
}
.dialog-contents {
  margin-bottom: 20px;
}
.BrandStatus-Dialog-Content {
  padding-right: 14px;
  padding-left: 14px;
}
.BrandStatus-Dialog-List {
  height: 325px;
  width: 100%;
  overflow: scroll;
  border: solid 1px #757575;
}
.BrandStatus-Cell {
  width: 100%;
  height: 48px;
  border-radius: 1px;
  border-bottom: solid 1px #757575;
}
.DateStyle {
  width: 327px;
  height: 56px;
}
.Temporarily-closed {
  font-family: Avenir;
  font-size: 13.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  max-height: 200px;
  margin-bottom: 20px;
}
.reopen-notice {
  font-size: 13.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}
a.disabled {
  pointer-events: all;
  color: #ccc;
}
.brand-name {
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.15px;
  color: #090c9b;
}
.Brand-Unit {
  margin: 1rem 0 0.5rem;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}
.svg-logo {
  width: 24px;
  height: 24px;
  display: inline-block;
  float: left;
  margin-right: 8px;
}
.kds {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #000000;
}
.v-card-custom {
  margin-bottom: 40px;
  padding: 0px;
}
.data-table >>> .v-datatable thead {
  background: #fafafa;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.data-table >>> .v-datatable thead tr:not(.v-datatable__progress) th:first-child,
.data-table >>> .v-datatable tbody tr td:first-child {
  padding: 28px 0px 28px 40px !important;
}
.data-table >>> .v-datatable thead th.column.sortable .v-icon {
  margin-left: 12px;
  vertical-align: middle;
}
a {
  text-decoration: underline;
}
div >>> .mdi-eye {
  color: green;
}
div >>> .mdi-eye-off {
  color: red;
}
div >>> .v-input--is-disabled .mdi-eye,
div >>> .v-input--is-disabled .mdi-eye-off {
  color: gray;
}
.svg-videocam {
  width: 30px;
  height: 30px;
  display: inline;
  border: none !important;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>

<style>
.layout.list-container.row.wrap {
  margin-top: 24px;
}
</style>
