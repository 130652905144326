import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", width: "700px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        VCard,
        { staticClass: "px-2 py-2" },
        [
          _c(
            VBtn,
            {
              staticStyle: { float: "right" },
              attrs: { icon: "", ripple: "" },
              on: { click: _vm.close }
            },
            [
              _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                _vm._v("mdi-close")
              ])
            ],
            1
          ),
          _vm.dialog
            ? _c("iframe", {
                attrs: {
                  src: "https://player.vimeo.com/video/" + _vm.videoID,
                  width: "675",
                  height: "350",
                  frameborder: "0",
                  allow: "autoplay; fullscreen",
                  allowfullscreen: ""
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }