import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs9: "" } },
            [
              _c("view-title", {
                attrs: {
                  address: _vm.address,
                  timezone: _vm.timezone,
                  dropDownItems: _vm.availableSites,
                  label: "Search Sites",
                  search: true
                }
              })
            ],
            1
          ),
          _c(
            VFlex,
            { staticStyle: { position: "relative" }, attrs: { xs3: "" } },
            [
              _c("br"),
              _vm.canEditSite
                ? _c(
                    "div",
                    { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { type: "submit", flat: "" },
                          on: { click: _vm.goToLocationSettings }
                        },
                        [
                          _c(VIcon, { attrs: { left: "", dark: "" } }, [
                            _vm._v("mdi-settings")
                          ]),
                          _vm._v("Configuration\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                staticClass: "svg-videocam",
                                attrs: { outline: "" },
                                on: { click: _vm.toggleDialog }
                              },
                              on
                            ),
                            [_c(VIcon, [_vm._v("mdi-help-circle")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Training Video")])]
              ),
              _c("video-player", {
                attrs: { dialog: _vm.videoDialog, videoID: _vm.videoID },
                on: {
                  "update:dialog": function($event) {
                    _vm.videoDialog = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(VSpacer),
      _c(
        "loading-wrapper",
        { attrs: { loading: _vm.loading, mt: "20vh", size: 100 } },
        [
          _c(
            VLayout,
            {
              staticClass:
                "mt-1 ml-1 ma-0 H6-Selected-On-Surface-High-Emphasis-Left"
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "32px", "font-weight": "500" } },
                [_vm._v("Station List")]
              ),
              _c(
                VLayout,
                { attrs: { row: "", wrap: "", "justify-end": "" } },
                [
                  !_vm.isDH && _vm.isAdmin
                    ? _c(
                        VFlex,
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { outline: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.goToBusinessUnitEditor({})
                                }
                              }
                            },
                            [_vm._v("Add Business Unit")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.locations.length > 0
                    ? _c(VFlex, { attrs: { shrink: "" } }, [
                        _vm.canAddBrand
                          ? _c(
                              "div",
                              [
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      type: "submit",
                                      color: "primary",
                                      disabled: _vm.isDH
                                    },
                                    on: { click: _vm.addNewBrand }
                                  },
                                  [
                                    _c(
                                      VIcon,
                                      { attrs: { left: "", dark: "" } },
                                      [_vm._v("mdi-plus")]
                                    ),
                                    _vm._v("ADD STATION\n            ")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            VLayout,
            { staticClass: "list-container", attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _vm._l(_vm.locations, function(location) {
                    return [
                      _c(
                        VCard,
                        {
                          key: location.id + "h3",
                          staticClass: "v-card-custom"
                        },
                        [
                          _c(
                            VLayout,
                            { staticClass: "locationPanel" },
                            [
                              _c(VFlex, { attrs: { shrink: "" } }, [
                                _c(
                                  "h3",
                                  { staticClass: "brand-name" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(location.name) +
                                        "\n                  "
                                    ),
                                    location && location.market_place
                                      ? _c(
                                          VChip,
                                          {
                                            staticStyle: {
                                              "border-radius": "6px"
                                            },
                                            attrs: {
                                              color: "primary",
                                              "text-color": "white",
                                              label: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Marketplace\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c("h4", { staticClass: "Brand-Unit" }, [
                                  _vm._v(
                                    "\n                  Business Unit Number\n                  " +
                                      _vm._s(
                                        (location.meta && location.meta.unit) ||
                                          "N/A"
                                      ) +
                                      "\n                "
                                  )
                                ])
                              ]),
                              _c(
                                VFlex,
                                { staticClass: "pencil" },
                                [
                                  (_vm.isAdmin || _vm.isOperator) && !_vm.isDH
                                    ? _c(
                                        VBtn,
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.goToBusinessUnitEditor(
                                                location
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(VIcon, [_vm._v("mdi-settings")])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(VDataTable, {
                            key: location.id,
                            staticClass: "data-table",
                            attrs: {
                              headers: _vm.headers,
                              items: location.brands,
                              "hide-actions": "",
                              search: _vm.filter,
                              loading: _vm.loading
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "clickable",
                                            staticStyle: {
                                              "max-width": "100px !important",
                                              "overflow-wrap": "break-word",
                                              width: "20%"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToBrand({
                                                  id: props.item.id,
                                                  name: props.item.name
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(props.item.name) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "loading-wrapper",
                                              {
                                                attrs: {
                                                  loading: !props.item,
                                                  justify: "justify-left",
                                                  size: 20
                                                }
                                              },
                                              [
                                                props.item
                                                  .brand_location_description
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "clickable",
                                                        staticStyle: {
                                                          width: "30%"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.goToBrand(
                                                              {
                                                                id:
                                                                  props.item.id,
                                                                name:
                                                                  props.item
                                                                    .name
                                                              }
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .brand_location_description
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      VTooltip,
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "a",
                                                                    _vm._g(
                                                                      {
                                                                        class: {
                                                                          clickable: true,
                                                                          disabled: !_vm.canEditSite
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.canEditSite &&
                                                                              _vm.editBrandDescription(
                                                                                props
                                                                                  .item
                                                                                  .id,
                                                                                props
                                                                                  .item
                                                                                  .name
                                                                              )
                                                                          }
                                                                        }
                                                                      },
                                                                      !_vm.canEditSite &&
                                                                        on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "Add Description"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Contact Implementation Team"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { width: "25%" } },
                                          [
                                            _c(
                                              "loading-wrapper",
                                              {
                                                attrs: {
                                                  loading: !props.item.hours,
                                                  justify: "justify-left",
                                                  size: 20
                                                }
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                        Pickup:\n                        "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "Body-1-Black-High-Emphasis-Left",
                                                      style: _vm.showBoldedHours(
                                                        props.item.pickup_times
                                                      )
                                                        ? "font-weight: bold"
                                                        : ""
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.goToBrand(
                                                                {
                                                                  id:
                                                                    props.item
                                                                      .id,
                                                                  name:
                                                                    props.item
                                                                      .name,
                                                                  tab: "pickup"
                                                                }
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .pickup_times
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]),
                                                props.item.delivery_times
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "\n                        Delivery:\n                        "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "Body-1-Black-High-Emphasis-Left",
                                                          style: _vm.showBoldedHours(
                                                            props.item
                                                              .delivery_times
                                                          )
                                                            ? "font-weight: bold"
                                                            : ""
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.goToBrand(
                                                                    {
                                                                      id:
                                                                        props
                                                                          .item
                                                                          .id,
                                                                      name:
                                                                        props
                                                                          .item
                                                                          .name,
                                                                      tab:
                                                                        "delivery"
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .delivery_times
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c(
                                                  VLayout,
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      VFlex,
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        props.item
                                                          .brand_status &&
                                                        props.item.brand_status
                                                          .is_closed
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "Temporarily-closed"
                                                              },
                                                              [
                                                                _c(VImg, {
                                                                  staticClass:
                                                                    "svg-logo",
                                                                  attrs: {
                                                                    src: require("@/assets/store-front.svg")
                                                                  }
                                                                }),
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      props.item
                                                                        .reopen_message
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass: "clickable",
                                            staticStyle: { width: "25%" },
                                            on: {
                                              click: function($event) {
                                                return _vm.goToBrand({
                                                  id: props.item.id,
                                                  name: props.item.name
                                                })
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "loading-wrapper",
                                              {
                                                attrs: {
                                                  loading: !props.item
                                                    .type_of_kds,
                                                  justify: "justify-left",
                                                  size: 20
                                                }
                                              },
                                              _vm._l(
                                                props.item.type_of_kds,
                                                function(kds, k) {
                                                  return _c(
                                                    VChip,
                                                    {
                                                      key: k,
                                                      staticClass: "kds",
                                                      attrs: {
                                                        disabled: "",
                                                        label: "",
                                                        draggable: "false"
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(kds))]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.shouldShowMenuColumn
                                          ? _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  padding: "0 !important"
                                                }
                                              },
                                              [
                                                _c(
                                                  VBtn,
                                                  {
                                                    staticClass: "ma-0",
                                                    staticStyle: {
                                                      "text-decoration": "none"
                                                    },
                                                    attrs: {
                                                      disabled: !_vm.brandMenuLink(
                                                        props.item
                                                      ),
                                                      outline: "",
                                                      href:
                                                        "" +
                                                        _vm.brandMenuLink(
                                                          props.item
                                                        ),
                                                      target: _vm.isCentricOSIntegrated(
                                                        props.item
                                                      )
                                                        ? "_blank"
                                                        : "_self",
                                                      color: "primary"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.getMenuButtonText(
                                                            props.item
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isDH
                                          ? _c(
                                              "td",
                                              [
                                                _c(
                                                  "loading-wrapper",
                                                  {
                                                    attrs: {
                                                      loading:
                                                        _vm.brandsUpdating[
                                                          props.item.id
                                                        ],
                                                      justify: "justify-left",
                                                      size: 20
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      VTooltip,
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        VCheckbox,
                                                                        {
                                                                          attrs: {
                                                                            "input-value":
                                                                              props
                                                                                .item
                                                                                .is &&
                                                                              props
                                                                                .item
                                                                                .is
                                                                                .hidden,
                                                                            "hide-details":
                                                                              "",
                                                                            "on-icon":
                                                                              "mdi-eye-off",
                                                                            "off-icon":
                                                                              "mdi-eye",
                                                                            color:
                                                                              "none",
                                                                            "false-value": false,
                                                                            "true-value": true,
                                                                            disabled: !_vm.isToggleVisibilityEnabled
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.toggleLocationBrandVisibility(
                                                                                props.item,
                                                                                $event
                                                                              )
                                                                            }
                                                                          },
                                                                          nativeOn: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        props.item.is &&
                                                        props.item.is.hidden
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                          Hidden in App\n                        "
                                                              )
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                "\n                          Display in App\n                        "
                                                              )
                                                            ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }